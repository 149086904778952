import {
  OrderByDirection,
  ReportsOrderBy,
  ReportsOrderByField,
} from '@/generated/graphql';

export enum SortByOption {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  A_TO_Z = 'sumitted-a-z',
  UP_VOTES = 'up-votes',
  DOWN_VOTES = 'down-votes',
  MOST_COMMENTS = 'most-comments',
}

export const getLabelForOption = (option: SortByOption): string => {
  switch (option) {
    case SortByOption.NEWEST:
      return 'Newest first';
    case SortByOption.OLDEST:
      return 'Oldest first';
    case SortByOption.A_TO_Z:
      return 'Submitted by A-Z';
    case SortByOption.UP_VOTES:
      return 'Up votes';
    case SortByOption.DOWN_VOTES:
      return 'Down votes';
    case SortByOption.MOST_COMMENTS:
      return 'Most comments';
  }
};

export const getReportsOrderByForOption = (
  option: SortByOption
): ReportsOrderBy => {
  switch (option) {
    case SortByOption.NEWEST:
      return {
        field: ReportsOrderByField.CREATED_AT,
        direction: OrderByDirection.DESC,
      };
    case SortByOption.OLDEST:
      return {
        field: ReportsOrderByField.CREATED_AT,
        direction: OrderByDirection.ASC,
      };
    case SortByOption.A_TO_Z:
      return {
        field: ReportsOrderByField.SUBMITTED_BY,
        direction: OrderByDirection.ASC,
      };
    case SortByOption.UP_VOTES:
      return {
        field: ReportsOrderByField.UPVOTES_COUNT,
        direction: OrderByDirection.DESC,
      };
    case SortByOption.DOWN_VOTES:
      return {
        field: ReportsOrderByField.DOWNVOTES_COUNT,
        direction: OrderByDirection.DESC,
      };
    case SortByOption.MOST_COMMENTS:
      return {
        field: ReportsOrderByField.COMMENTS_COUNT,
        direction: OrderByDirection.DESC,
      };
    default:
      throw new Error(`Unknown option kind: ${option}`);
  }
};
