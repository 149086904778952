import sortBy from 'lodash/sortBy';
import {
  ReportsInput,
  ScamCategoryKind,
  useChainsQuery,
  useScamCategoriesQuery,
} from '@/generated/graphql';
import {
  CategoryFilterOption,
  ChainFilterOption,
  Filter,
} from '@/types/filter';
import { QueryParamNames } from '@/types/routes';
import useResultsNavigationQueryParams from './useResultsNavigationQueryParams';

type UseFilterOptions = ReportsInput;

type UseFilterState = {
  selectedFilter?: Filter;
  categoryFilterOptions?: CategoryFilterOption[];
  chainFilterOptions?: ChainFilterOption[];
};

type UseFilterActions = {
  onAddFilter: (filter: Filter) => Promise<boolean>;
  onRemoveFilter: () => Promise<boolean>;
};

export type UseFilterValue = UseFilterState & UseFilterActions;

const useFilter = (props?: UseFilterOptions): UseFilterValue => {
  const shouldGetChainFilterOptions = props?.scamCategories !== undefined;
  const shouldGetCategoryFilterOptions =
    props?.chains !== undefined ||
    props?.address !== undefined ||
    props?.reporterId !== undefined ||
    props?.org?.id !== undefined;

  // we don't want to refetch the query if chains length is 0
  const chains =
    props?.chains && props.chains.length > 0 ? props.chains : undefined;

  // we don't want to refetch the query if scamCategories length is 0
  const scamCategories =
    props?.scamCategories && props.scamCategories.length > 0
      ? props.scamCategories
      : undefined;

  const { data: chainData } = useChainsQuery({
    variables: {
      input: {
        scamCategories,
        // TODO: passing these leads to invalid caching, temporarily disabling
        // org: props?.org,
        // dateFilter: props?.dateFilter,
      },
    },
  });

  const { data: categoryData } = useScamCategoriesQuery({
    variables: {
      input: {
        chains,
        address: props?.address,
        reportedById: props?.reporterId,
        org: props?.org,
        // TODO: passing this leads to invalid caching, temporarily disabling
        // dateFilter: props?.dateFilter,
      },
    },
  });

  const chainOptions = chainData?.chains ?? [];
  const categoryOptions = sortBy(
    categoryData?.scamCategories ?? [],
    ({ kind }) => {
      // make `Other` category last
      return kind === ScamCategoryKind.OTHER ? 1 : -1;
    }
  );

  const { params, setParams } = useResultsNavigationQueryParams();
  const { filter } = params;

  const addFilter = (filter: Filter) =>
    setParams({
      ...params,
      [QueryParamNames.PAGE]: 0, // go back to first page when filter updated
      [QueryParamNames.FILTER]: filter,
    });

  const removeFilter = () =>
    setParams({
      ...params,
      [QueryParamNames.PAGE]: 0,
      [QueryParamNames.FILTER]: null,
    });

  return {
    chainFilterOptions: shouldGetChainFilterOptions
      ? chainOptions.map(({ kind, reportsFiledCount }) => ({
          filter: kind,
          numResults: reportsFiledCount,
        }))
      : undefined,
    categoryFilterOptions: shouldGetCategoryFilterOptions
      ? categoryOptions.map(({ kind, reportsFiledCount }) => ({
          filter: kind,
          numResults: reportsFiledCount,
        }))
      : undefined,
    selectedFilter: filter ?? undefined,
    onAddFilter: addFilter,
    onRemoveFilter: removeFilter,
  };
};

export default useFilter;
