import { offsetToCursor } from 'graphql-relay';
import {
  ChainKind,
  GetReportsQueryVariables,
  ScamCategoryKind,
} from '@/generated/graphql';
import { SortByOption } from '@/types/sort-by';
import { getReportsOrderByForOption } from './../../types/sort-by';

export const DEFAULT_RESULTS_PER_PAGE = 15;
export const calculateAfterCursor = (page: number, resultsPerPage: number) =>
  offsetToCursor(page * resultsPerPage - 1);

type InitialVariableReportsInputProps = {
  address?: string;
  domain?: string;
  chains?: ChainKind[];
  scamCategories?: ScamCategoryKind[];
  page?: number;
  resultsPerPage?: number;
};

/**
 * We are SSRing the [addressQuery], [domainQuery], and [categorySlug] pages
 * and loadingresults server side into the Apollo cache. In order for Apoll
 * have a cache hit when the page loads client side, the initial query variables
 * in both the useResults hook client side and the client.query({ query:
 * GetReportsQuery }) server side must match.
 *
 * This utility function centralizes the logic for getting the initial
 * variables for this query. NOTE: it intentionally only handles the
 * base route cases (e.g. /address, /domain, /category). If there are
 * additional query params (?page, ?sort, etc) that need to be handled,
 * you should create your own input.
 */
export const getInitialVariablesForGetReportsQuery = ({
  address,
  domain,
  chains = [],
  scamCategories = [],
  page = 0,
  resultsPerPage = DEFAULT_RESULTS_PER_PAGE,
}: InitialVariableReportsInputProps): GetReportsQueryVariables => {
  const after = calculateAfterCursor(page, resultsPerPage);
  return {
    input: {
      address,
      domain,
      chains,
      scamCategories,
      orderBy: getReportsOrderByForOption(SortByOption.NEWEST),
      dateFilter: undefined,
      query: undefined,
      reporterId: undefined,
      org: undefined,
    },
    first: DEFAULT_RESULTS_PER_PAGE,
    after,
  };
};
