import {
  useScamCategoriesQuery,
  ScamCategoriesQueryResult,
} from '@/generated/graphql';
import { ScamCategory } from '@/types/scam-categories';

type ScamCategories =
  | NonNullable<ScamCategoriesQueryResult['data']>['scamCategories']
  | null;

type UseScamCategoriesValue = {
  /**
   * Returns top 5 scam categories
   */
  topScamCategoriesWithReports: ScamCategories;

  /**
   * Returns all the scam categories with reports
   */
  allScamCategoriesWithReports: ScamCategories;

  loading: boolean;
};

const TOP_CATEGORIES_COUNT = 8;

export const useScamCategories = (): UseScamCategoriesValue => {
  const { data, loading } = useScamCategoriesQuery({
    variables: { input: {} },
  });

  const allScamCategoriesWithReports = data?.scamCategories ?? null;

  const topScamCategoriesWithReports =
    allScamCategoriesWithReports
      ?.filter((c) => c.kind !== ScamCategory.OTHER)
      ?.slice(0, TOP_CATEGORIES_COUNT) ?? null;

  return {
    allScamCategoriesWithReports,
    topScamCategoriesWithReports,
    loading,
  };
};
