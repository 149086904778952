import { ScamReportDetailsProps } from '@/features';
import { isSerializedEditorState } from '@/features/submit-report-page/utils/isSerializedEditorState';
import { ReportFragment, VoteDirection } from '@/generated/graphql';

/**
 * Util to translate a report received the backend to the set of
 * data props can be rendered as a ScamReportCard
 */
export const getScamReportCardPropsFromReport = (
  report: ReportFragment
): ScamReportDetailsProps => {
  return {
    isPrivate: report.isPrivate,
    reportId: report.id,
    category: report.scamCategory,
    categoryDescription: report.categoryDescription ?? undefined,
    description: report.description ?? '',
    lexicalSerializedDescription: isSerializedEditorState(
      report.lexicalSerializedDescription
    )
      ? report.lexicalSerializedDescription
      : undefined,
    addresses: report.addresses,
    reportDate: new Date(report.createdAt),
    authorUsername: report.reportedBy?.username,
    numComments: report.commentsCount,
    vote: report.viewerDidVote
      ? report.viewerDidVote === VoteDirection.UP
        ? 'up'
        : 'down'
      : undefined,
    netVoteCount: report?.biDirectionalVoteCount,
    trusted: report.reportedBy.trusted ?? false,
    source: report.source,
    checked: report.checked ?? false,
  };
};
