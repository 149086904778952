import {
  queryTypes,
  Serializers,
  SerializersWithDefaultFactory,
  SetValues,
  useQueryStates,
  Values,
} from 'next-usequerystate';
import { ChainType } from '@/types/chain';
import { Filter } from '@/types/filter';
import { QueryParamNames } from '@/types/routes';
import { ScamCategory } from '@/types/scam-categories';
import { SortByOption } from '@/types/sort-by';

type UseResultsNavigationQueryParamsOptions = {
  /* Initial sort */
  initialSort?: SortByOption;
};

type UseResultsNavigationQueryParamsState = {
  /** The page index in the query params */
  params: Values<{
    [QueryParamNames.PAGE]: Serializers<number> & {
      readonly defaultValue: number;
    };
    [QueryParamNames.FILTER]: SerializersWithDefaultFactory<Filter>;
    [QueryParamNames.SORT]: SerializersWithDefaultFactory<SortByOption>;
  }>;
};

type UseResultsNavigationQueryParamsActions = {
  setParams: SetValues<{
    [QueryParamNames.PAGE]: Serializers<number> & {
      readonly defaultValue: number;
    };
    [QueryParamNames.FILTER]: SerializersWithDefaultFactory<Filter>;
    [QueryParamNames.SORT]: SerializersWithDefaultFactory<SortByOption>;
  }>;
};

export type UseResultsNavigationQueryParamsValue =
  UseResultsNavigationQueryParamsState & UseResultsNavigationQueryParamsActions;

const useResultsNavigationQueryParams =
  (): UseResultsNavigationQueryParamsValue => {
    const [filterSortPageParams, setFilterSortPageParams] = useQueryStates({
      [QueryParamNames.PAGE]: queryTypes.integer.withDefault(0),
      [QueryParamNames.FILTER]: queryTypes.stringEnum<Filter>([
        ...Object.values(ChainType),
        ...Object.values(ScamCategory),
      ]),
      [QueryParamNames.SORT]: queryTypes.stringEnum<SortByOption>(
        Object.values(SortByOption)
      ),
    });

    return {
      params: filterSortPageParams,
      setParams: setFilterSortPageParams,
    };
  };

export default useResultsNavigationQueryParams;
