import { ApolloError } from '@apollo/client';
import { queryTypes, useQueryState } from 'next-usequerystate';
import { useEffect, useMemo } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { useRouter } from 'next/router';
import { MAX_SIGNED_OUT_PAGES } from '@/constants';
import {
  ChainKind,
  ReportFragment,
  ReportsInput,
  ScamCategoryKind,
  useGetReportsLazyQuery,
  useGetReportsQuery,
} from '@/generated/graphql';
import { SortByOption } from '@/types/sort-by';
import { DEFAULT_RESULTS_PER_PAGE } from '@/utils/apollo/getInitialVariablesForGetReportsQuery';
import { useAuthRequiredAction } from '..';
import { calculateAfterCursor } from './../../utils/apollo/getInitialVariablesForGetReportsQuery';
import useFilter from './useFilters';
import useSort, { UseSortValue } from './useSort';

export type UseResultsOptions = Omit<ReportsInput, 'orderBy'> & {
  /**
   * The numbers per page to show.
   * @default 15
   */
  resultsPerPage?: number;

  /**
   * The initial sort to apply to results
   */
  initialSort?: SortByOption;

  /**
   * Whether this hook should request results from the next page prior so
   * they will be in cache before the consumer requests them.
   * @default true
   */
  lookAhead?: boolean;

  /**
   * Whether to disable updating the sort query param.
   */
  shouldDisableSortQueryParam?: boolean;
};

type UseResultsState = {
  /** The results to display on the current page */
  results?: ReportFragment[];

  /** The page the user is on */
  page: number;

  numPages: number;

  firstResultOnPageNum: number;

  lastResultOnPageNum: number;

  totalCount: number;

  isLoading: boolean;

  /**
   * An error from getting results if one exists
   */
  error?: ApolloError;

  organizationId?: string;
};

type UseResultsActions = {
  onPageChange: (page: number) => void;
};

type UseResultsValue = ReturnType<typeof useFilter> &
  Omit<UseSortValue, 'getOrderByForSelectedSort'> &
  UseResultsState &
  UseResultsActions;

const filterIsChainKind = (filter: any): filter is ChainKind => {
  return Object.values(ChainKind).indexOf(filter) !== -1;
};

const filterIsScamCategory = (filter: any): filter is ScamCategoryKind => {
  return Object.values(ScamCategoryKind).indexOf(filter) !== -1;
};

const useResults = (options?: UseResultsOptions): UseResultsValue => {
  const chains = useDeepCompareMemo(() => {
    if (options?.chains) return options.chains;
    if (options?.chain) return [options.chain];
    return [];
  }, [options?.chains, options?.chain]);

  const scamCategories = useDeepCompareMemo(() => {
    if (options?.scamCategories) return options.scamCategories;
    if (options?.scamCategory) return [options.scamCategory];
    return [];
  }, [options?.scamCategories, options?.scamCategory]);

  const dateFilter = useMemo(() => {
    return options?.dateFilter?.before || options?.dateFilter?.since
      ? {
          before: options.dateFilter.before,
          since: options.dateFilter.since,
        }
      : undefined;
  }, [options?.dateFilter?.before, options?.dateFilter?.since]);

  const filterState = useFilter({
    ...options,
    chains,
    scamCategories,
    dateFilter,
  });
  const { selectedSort, onSelectSort, getOrderByForSelectedSort } = useSort({
    initialSort: options?.initialSort,
  });

  const [page, setPage] = useQueryState(
    'page',
    queryTypes.integer.withDefault(0)
  );
  const { isLoggedIn, openAuthModal, isLoading } = useAuthRequiredAction();
  useEffect(() => {
    if (!isLoading && !isLoggedIn && page > MAX_SIGNED_OUT_PAGES) {
      setPage(MAX_SIGNED_OUT_PAGES);
      openAuthModal();
    }
  }, [isLoading, isLoggedIn, openAuthModal, page, setPage]);

  const resultsPerPage = options?.resultsPerPage ?? DEFAULT_RESULTS_PER_PAGE;

  const { selectedFilter } = filterState;

  const input: ReportsInput = useMemo(() => {
    return {
      address: options?.address ?? undefined,
      chains,
      scamCategories,
      reporterId: options?.reporterId ?? undefined,
      org: options?.org?.id
        ? { id: options.org.id, filterBy: options.org.filterBy }
        : undefined,
      orderBy: getOrderByForSelectedSort(selectedSort),
      ...(selectedFilter &&
        filterIsChainKind(selectedFilter) && { chains: [selectedFilter] }),
      ...(selectedFilter &&
        filterIsScamCategory(selectedFilter) && {
          scamCategories: [selectedFilter],
        }),
      query: options?.query ?? undefined,
      dateFilter,
      domain: options?.domain ?? undefined,
    };
  }, [
    options?.address,
    options?.reporterId,
    options?.org?.id,
    options?.org?.filterBy,
    options?.query,
    chains,
    scamCategories,
    getOrderByForSelectedSort,
    selectedSort,
    selectedFilter,
    dateFilter,
    options?.domain,
  ]);

  const after = calculateAfterCursor(page, resultsPerPage);
  /**
   * @TODO In order for SSR to work, these initial variables client side need to
   * match the variables that are passed to the query server side.
   *
   * @see getInitialVariablesForGetReportsQuery
   * We do not use it here because we want to be able to use the same hook
   * to also handle query params, user interaction, and other use cases like
   * top results, etc.
   */
  const initialVariables = {
    input,
    first: resultsPerPage,
    after,
  };
  const [getReports] = useGetReportsLazyQuery();
  const { data, loading, error } = useGetReportsQuery({
    fetchPolicy: 'cache-first',
    variables: initialVariables,
  });

  // look ahead
  const lookAhead = options?.lookAhead ?? true;
  useEffect(() => {
    if (lookAhead) {
      const nextPageCursor = calculateAfterCursor(page + 1, resultsPerPage);
      // this gets the next page of reports
      getReports({
        variables: {
          input,
          first: resultsPerPage,
          after: nextPageCursor,
        },
      });
    }
  }, [getReports, input, page, resultsPerPage, lookAhead]);

  const query = data?.reports;
  const totalCount = query?.totalCount ?? 0;

  const results =
    query?.edges
      ?.map(({ node }) => node)
      .filter((node): node is ReportFragment => !!node)
      .slice(0, resultsPerPage) ?? [];

  const numPages = Math.max(1, Math.ceil(totalCount / resultsPerPage));

  const onPageChange = (page: number) => setPage(page - 1);

  const startIndex = page * resultsPerPage + 1;
  const endIndex = startIndex + results.length - 1;

  return {
    ...filterState,
    error,
    // only loading if query hasn't run or number of results is too few
    isLoading: !data && !error && loading,
    results,
    page: page + 1,
    firstResultOnPageNum: startIndex,
    lastResultOnPageNum: endIndex,
    totalCount,
    numPages,
    selectedSort,
    onSelectSort,
    onPageChange,
  };
};

export default useResults;
