import { useState } from 'react';
import { ReportsOrderBy } from '@/generated/graphql';
import { QueryParamNames } from '@/types/routes';
import { getReportsOrderByForOption, SortByOption } from '@/types/sort-by';
import useResultsNavigationQueryParams from './useResultsNavigationQueryParams';

export type UseSortOptions = {
  /**
   * The initial sort to apply to results
   * @default SortByOption.NEWEST
   */
  initialSort?: SortByOption;
};

type UseSortState = {
  selectedSort: SortByOption;
};

type UseSortAction = {
  onSelectSort: (optionKind: SortByOption) => void;
  getOrderByForSelectedSort: (selected: SortByOption) => ReportsOrderBy;
};

export type UseSortValue = UseSortState & UseSortAction;

const useSort = (options?: UseSortOptions): UseSortValue => {
  const { params, setParams } = useResultsNavigationQueryParams();
  const [selectedSort, setSelectedSort] = useState<SortByOption>(
    params[QueryParamNames.SORT] ?? options?.initialSort ?? SortByOption.NEWEST
  );

  const onSelectSort = (optionKind: SortByOption) => {
    setParams({
      ...params,
      [QueryParamNames.SORT]: optionKind,
      [QueryParamNames.PAGE]: 0,
    });
    setSelectedSort(optionKind);
  };

  return {
    selectedSort,
    onSelectSort,
    getOrderByForSelectedSort: getReportsOrderByForOption,
  };
};

export default useSort;
