import clsx from 'clsx';
import { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Icon, Modal } from '@/components';
import { svgCheckmark, svgGreenCheckmark, svgUnverified } from '@/assets/icons';

export type BadgeInfoModalContentProps = StyleProps & {
  // add props here
};

const ROOT = makeRootClassName('BadgeInfoModalContent');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function BadgeInfoModalContent(
  props: BadgeInfoModalContentProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <Modal.Content size="large" className={clsx(ROOT, p.className)}>
      <Modal.Header
        title="What do the badges on reports mean?"
        hasCloseButton
      />
      <div className={el`badge-section`}>
        <p className={el`badge-title`}>
          <span>&lsquo;Trusted Contributor&rsquo; Badge</span>
          <Icon content={svgCheckmark} tooltipContent="Trusted Contributor" />
        </p>
        <p className={el`badge-description`}>
          The blue &lsquo;Trusted Contributor&rsquo; badge means that this
          report was filed by a pre-vetted partner from the Web3 Security
          Network, who can attest to the information provided on the report.
        </p>
      </div>
      <div className={el`badge-section`}>
        <p className={el`badge-title`}>
          <span>&lsquo;Checked Report&rsquo; Badge</span>
          <Icon content={svgGreenCheckmark} tooltipContent="Checked Report" />
        </p>
        <p className={el`badge-description`}>
          The green &lsquo;Checked by Chainabuse&rsquo; badge indicates that our
          team of moderators, including blockchain intelligence experts who
          specialize in identifying crypto crime, checked the report against
          spam. Please note that there can be no guarantee that the information
          is 100% accurate.
        </p>
      </div>
      <div className={el`badge-section`}>
        <p className={el`badge-title`}>
          <span>&lsquo;Not Verified Report&rsquo; Badge</span>
          <Icon content={svgUnverified} tooltipContent="Not Verified Report" />
        </p>
        <p className={el`badge-description`}>
          The “Not Verified Report” Badge means that this report has not been
          shared by a Trusted Contributor and has not been checked against spam
          by our team of moderators yet. Please use it with cautiousness.
        </p>
      </div>
    </Modal.Content>
  );
}

export default BadgeInfoModalContent;
